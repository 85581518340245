import revive_payload_client_4sVQNw7RlN from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_C2QvvtgUo3 from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import plugin_tbFNToZNim from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_w2VlvAEcpa from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_Jozdw60ZsE from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import script_YrMPz5ICtR from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import strapi_m0ehjFxJWT from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.js";
import cookies_client_Rz3izNprJ6 from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/plugins/cookies.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_C2QvvtgUo3,
  plugin_tbFNToZNim,
  plugin_w2VlvAEcpa,
  plugin_Jozdw60ZsE,
  script_YrMPz5ICtR,
  strapi_m0ehjFxJWT,
  cookies_client_Rz3izNprJ6
]