import type Image from "@/typings/strapi/image";
import type MediaObject from "~/typings/strapi/media";
import type { Strapi5ResponseSingle, Strapi5ResponseMany } from "@nuxtjs/strapi";

export function flattenObject<T>(object: Strapi5ResponseSingle<T> | undefined | null) {
  return object?.data as T;
}

export function flattenCollection<T>(collection: Strapi5ResponseMany<T> | undefined | null) {
  if (!collection?.data) {
    return [];
  }

  return collection.data as Array<T>;
}

export function flattenCollectionToSingle<T>(collection: Strapi5ResponseMany<T> | undefined | null) {
  if (!collection?.data || collection.data.length === 0) {
    return null;
  }

  return collection.data[0] as T;

}

export function getStrapiImageLocation(image: MediaObject | undefined | null, prefixed: boolean = false, returnAsString: boolean = true) {
  if (!image) {
    return returnAsString ? 'undefined' : undefined;
  }

  return getFlattenedStrapiImageLocation(image, prefixed);
}

export function getFlattenedStrapiImageLocation(image: Image | MediaObject | undefined | null, prefixed: boolean = false, returnAsString: boolean = true) {
  if (!image) {
    return returnAsString ? 'undefined' : undefined;
  }

  let value = `${image.hash || (returnAsString ? 'undefined' : undefined)}${image.ext}`;

  if (prefixed) {
    const runtimeConfig = useRuntimeConfig();
    value = `${runtimeConfig.public.strapi.url}/uploads/${value}`;
  }

  return value;
}
