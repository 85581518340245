<template>
  <nav class="nav-wrapper">
    <div class="nav--desktop">
      <ul class="nav__items">
        <li v-for="navItem in navItems" :key="navItem.id">
          <NavigationLink :item="navItem"/>
        </li>
      </ul>
      <NavigationCornerLogo/>
    </div>
    <div class="nav--mobile">
      <div class="navigation__header">
        <NavigationBurger v-model="mobileOpen" />
        <NavigationCornerLogo/>
      </div>
      <div class="navigation__content" :class="{ open: mobileOpen }">
        <NavigationLink v-for="item in navItems" :item="item" :key="item.id" is-mobile @click="closeMenu" />
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import type Menu from '@/typings/strapi/menu';
import type MenuItem from '@/typings/strapi/menuItem';

const { findOne } = useStrapi();

// TODO: removed till menu plugin is updated, for now just use static data
// const { data } = await useAsyncData(
//   'headerMenu',
//   () => findOne<Menu>('menus', 1, { populate: '*', nested: true }),
//   {
//     transform: flattenObject
//   }
// )

// const navItems = computed(() => flattenCollection<MenuItem>(data.value?.items));

const navItems: MenuItem[] = [
  {
    id: 1,
    title: 'Vacatures',
    url: '/#vacatures',
    order: 0,
    createdAt: '2024-12-01T12:00:00.000Z',
    updatedAt: '2024-12-01T12:00:00.000Z',
  },
  {
    id: 2,
    url: '/#5-stappen',
    title: '5 stappen',
    order: 1,
    createdAt: '2024-12-01T12:00:00.000Z',
    updatedAt: '2024-12-01T12:00:00.000Z',
  },
  {
    id: 3,
    url: '/#over',
    title: 'Over',
    order: 2,
    createdAt: '2024-12-01T12:00:00.000Z',
    updatedAt: '2024-12-01T12:00:00.000Z',
  },
  {
    id: 4,
    url: '/contact',
    title: 'Contact',
    order: 3,
    createdAt: '2024-12-01T12:00:00.000Z',
    updatedAt: '2024-12-01T12:00:00.000Z',
  }
]


const mobileOpen = ref<boolean>(false);

const closeMenu = () => mobileOpen.value = false;
</script>
